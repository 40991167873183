<template>
  <div id="app" style="background-color: white;">
      <div class="banner">
        <a href="/">
          <img class="logo" src="./logo.png">
        </a>
      </div>
      
      <div class="wrapper" v-if="aula">
        <div id="text">
          <h1>Aula</h1>
          <h3>{{ nomeAula }}</h3>
          <hr>
          <p>Scansiona il QRCode utilizzando l'app per segnare la tua presenza {{ lockok?'..':'' }}  {{ lockno?'.':'' }}</p>
          
          <div class="bar-container" v-on:click="fullscreen()">
            <div v-bind:class="barcolor" v-bind:style="{ width: sizeperc+'%' }">{{ countdown }}</div>
          </div>
        </div>
        <br>
        <div>
          <qrcode-vue class="qr" v-if="code>''" :value="code" :size="size"></qrcode-vue>
        </div>
      </div>
      
      <div class="wrapper" v-if="!aula && !tavolo">
        <div class="input">
          <h1>Inserire il codice dell'aula</h1>
          <input type="text" v-model="codiceaula">
          <a v-on:click="salvaaula" :style="{opacity: codiceaula != '' ? '100' : '0'}">Conferma</a>
        </div>
      </div>
      

    <div class="wrapper" v-if="tavolo">
      <h3>Tavolo N. {{ tavolo }}</h3>
      <qrcode-vue style="margin-top: 10%" class="qr" v-if="code>''" :value="code" :size="size"></qrcode-vue>
    </div>

  </div>
</template>

<script>
import qrcodeVue from 'qrcode.vue'
import apiclient from './apiclient.js'

export default {
  name: 'app',
  data: function() {
    return {
      aula: '',
      codiceaula: '',
      nomeAula: '',
      code:'',
      countdown: 0,
      sizeperc: 100,
      timer:{},
      barcolor: 'bargreen',
      size: 240,
      wakeLock:{},
      lockok:false,
      lockno:false,
      abilitato: false,
      clientIP: '',
      tavolo: null,
      url: 'https://docenti.scuolacucina.it/#/loginesterni?otp=',
      // url: 'https://devalmadocet.gnet.it/#/loginesterni?otp='
    }
  },
  components: {
    qrcodeVue
  },
  mounted: async function() {
    this.getParamFromUrl();

    if (location.search>'' && this.tavolo){ console.log('RESETTO AULA'); localStorage.setItem("alma_aula", ''); }
    if(localStorage.getItem('alma_aula')) this.aula=localStorage.getItem("alma_aula");
    if (this.aula || this.tavolo) {
      this.getIp();
      // this.gestionetotp();
    }
    this.addWakelock();
},
  created(){

   // this.controlla();
  },
  methods: {
    getParamFromUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.tavolo = urlParams.get('tavolo');
      this.aula = urlParams.get('aula');
      if(this.aula && this.tavolo){
        this.aula = '';
        this.tavolo = '';
        alert('Indirizzo errato!');
      }
      // if(this.tavolo){
      //   console.log('Parametro tavolo', this.tavolo);
      //   this.gestionetotp();
      // }
      // if(this.aula){
      //   console.log('Parametro aula', this.aula);
      //   this.getIp();
      // }
    },

    getIp(){
      console.log('getIp');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/getIP', true);

      let self=this;

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          // La risposta contiene l'indirizzo IP restituito dal server
          const jsonData=JSON.parse(xhr.responseText);
          self.clientIP=jsonData.userIp;
          console.log(self.clientIP);
          self.controlla();
        } else {
          console.error('Qualcosa è andato storto nella richiesta.');
        }
      };

      xhr.send();
    },

    controlla(){
      console.log('controlla - aula:', this.aula, ', tavolo:', this.tavolo);
      var self= this;
      // if(this.aula){
        //const clientIP = window.location.hostname;
        //console.log('Questo ==>',this.clientIP)
        var data = {callname: 'addIP',ip: this.clientIP, aulaId: this.aula, tavolo: this.tavolo};
        //console.log("data =====>", data);
        apiclient.addIP(data).then(function(resp) {   
          console.log(resp); 
          data = {callname: 'getStato', ip: self.clientIP};
          apiclient.getStato(data).then(function(stato) {
            //console.log('ricevuto:'+data.callname);
            console.log('STATO = ', stato);
            if(stato){
              console.log('ABILITATO APP TABLET');
              self.abilitato = true;
              self.gestionetotp();
              if(self.aula)
                self.getAula();
            }else
            console.log('NON ABILITATO APP TABLET');        
          })
        })
        
      // }
      
    },

    getAula: async function(){
      var self=this;
      if(this.aula){
        var data={callname:"getAula"}
        await apiclient.getcandidatura2(data).then(function(resp){
          console.log(resp);
          if(resp){
            for(let aula of resp){
              //console.log(aula.Name);
              if(aula.Key__c == self.aula)
                self.nomeAula=aula.Name;
                //console.log(self.nomeAula);
            }
          }
        })
      }
    },
    
    salvaaula:function() {
      console.log('salvaaula');
      this.aula=this.codiceaula
      localStorage.setItem("alma_aula", this.aula);
      window.location='/'; // restart
    },
    fullscreen:function() {
      let theapp=document.getElementById('app');
      if (theapp) {
        theapp.requestFullscreen();
      }
    },
    async addWakelock(){
      console.log('addWakelock');
      try {
        if ("wakeLock" in navigator) {
          this.wakeLock = await navigator.wakeLock.request('screen');
          this.lockok=true;
          console.log('lock ok');

          setTimeout(()=>{
            this.releaseWakelock();   //Dopo 15 minuti rilascia il blocco schermo attivo.
          }, 15*60*1000);             //Decorsi i 15 minuti lo schermo si spegnerà dopo il tempo settato nelle impostazioni schermo.

        } else {
          this.lockno=true;
          console.log('lock not supported');
        }
      } catch (e) {
        console.log(e)
      }
    },
    releaseWakelock() {
      if (this.wakeLock) {
        this.wakeLock.release();
        this.lockok = false;
        console.log('lock released');
      }
    },

    gestionetotp: async function() {
      if(this.abilitato/* || this.tavolo*/){
        var data
        if(this.tavolo){
          data = { callname: 'generateTOTPGiudici' };
          this.code=this.url + await apiclient.getcandidatura2(data) + '&tav='+this.tavolo;
        }else if(this.aula){
          data = { callname: 'generateTOTP', aulaId: this.aula, ip: this.clientIP };
          this.code=await apiclient.getcandidatura2(data)+';'+this.aula;
          localStorage.setItem('alma_aula', '');
        }
        this.timer=setInterval( function (self) {
          var epoch = Math.round(new Date().getTime() / 1000.0);
          self.countdown = 30 - (epoch % 30);
          self.sizeperc=parseFloat(self.countdown / 30 * 100).toFixed(0);
          self.barcolor='bargreen'
          if (self.countdown<5) self.barcolor='barred'
          if ((epoch % 30)==0) {
            console.log('new code');
            apiclient.getcandidatura2(data).then( (resp) => {
              console.log(resp);
              if(self.tavolo)
                self.$set(self, 'code', self.url+resp+'&tav='+self.tavolo);
              else
                self.$set(self, 'code', resp+';'+self.aula);
            })
          }
        }, 1000, this)
      }
    }
    
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


:root {
  --main: #283c50;
  --main-lighter: #3b5674;
}
* {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Avenir, Arial, sans-serif;
  overflow: hidden;
}
*:focus {
  outline: none;
}

.logo {
  height: 80%;
  margin: 0.5vh 1vw 0.5vh;
}

.banner {
  background-color: var(--main);
  width: 100%;
  display: flex;
  align-items: center;
}


#app > .qr{
  width: 80%;
  max-height: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bargreen {
  background-color: #283c50;
  text-align: center;
  height: 1.5em;
}

.barred {
  background-color: lightcoral;
  text-align: center;
  height: 1.5em;
}

.bar-container {
  color: white;
  width: 100%;
  height: 1.5em;
  background-color: #b9b9b9;
  margin-top: 2vh;
  border-radius: 150px;
  width: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding-top: 2em;
}

.text {
  max-width: 50%;
  word-wrap: break-word;
}
h1 {
  font-weight: 600;
}
h3 {
  font-weight: 700;
  font-size: 50px;
}
p {
  margin-top: 3vh;
}


.input {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.input input {
  margin-top: 4vh;
  border: none;
  border-bottom: 2px solid #cbcbcb;
  font-size: 35px;
  text-align: center;
}
.input a {
  text-decoration: none;
  color: white;
  background-color: var(--main);
  padding: 2.5vh 2vw 2.5vh;
  border-radius: 250px;
  margin-top: 3vh;
  transition-duration: 150ms;
}
.input a:hover {
  background-color: var(--main-lighter);
  transition-duration: 150ms;
}

@media screen and (orientation: portrait) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;  
  }

  #app > .qr {
    align-self: flex-end; /* Posiziona il QR code nella parte inferiore dello schermo in modalità ritratto */
    margin-top: 2vh; /* Aggiorna il margine superiore se necessario */
  }

  .text {
    max-width: 100%;
  }

  #text{
    text-align: center;
    max-width: 100%;
  }
}

@media screen and (orientation: landscape) {
  #app > .qr {
    align-self: flex-start; /* Posiziona il QR code sulla destra in modalità paesaggio */
  }

  .wrapper {
    flex-direction: row; /* Ripristina la direzione del flex container in modalità paesaggio */
  }

  .text {
    max-width: 50%;
  }
}
</style>